import React, { useState, useEffect } from 'react';
import AvailableDateService from '../../services/AvailableDateService';

function AvailableDateList() {
  const [availableDates, setAvailableDates] = useState([]);
  const [editAvailableDate, setEditAvailableDate] = useState(null);
  const [newAvailableDate, setNewAvailableDate] = useState({
    availableDate: '',
    doctorId: '',
  });

  useEffect(() => {
    fetchAvailableDates();
  }, []);

  const fetchAvailableDates = async () => {
    try {
      const data = await AvailableDateService.getAvailableDates();
      setAvailableDates(data);
    } catch (error) {
      console.error('Error fetching available dates:', error);
    }
  };

  const handleEditClick = (date) => {
    setEditAvailableDate({ ...date });
  };

  const handleCreate = async () => {
    try {
      await AvailableDateService.createAvailableDate(newAvailableDate);
      fetchAvailableDates();
      setNewAvailableDate({ availableDate: '', doctorId: '' });
    } catch (error) {
      console.error('Error creating available date:', error);
    }
  };



  const handleUpdate = async () => {
    try {
      await AvailableDateService.updateAvailableDate(editAvailableDate.id, editAvailableDate);
      fetchAvailableDates();
      setEditAvailableDate(null); // Edit modunu kapat
    } catch (error) {
      console.error('Error updating available date:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await AvailableDateService.deleteAvailableDate(id);
      fetchAvailableDates();
    } catch (error) {
      console.error('Error deleting available date:', error);
    }
  };

  return (

      <div className="available-date-container">

      <h2 style={{ marginLeft: '50px', paddingBottom: '20px' }}>Müsait Günler</h2>

      <div id="date-creation-form" style={{ marginLeft: '50px', paddingBottom: '20px' }}> 
                <input
                  type="date"
                  value={newAvailableDate.availableDate}
                  onChange={(e) => setNewAvailableDate({ ...newAvailableDate, availableDate: e.target.value })}
                />
                <input
                  type="number"
                  value={newAvailableDate.doctorId}
                  onChange={(e) => setNewAvailableDate({ ...newAvailableDate, doctorId: e.target.value })}
                  placeholder="Doktor ID"
                />
                <button onClick={handleCreate} style={{ backgroundColor: '#8294C4' }}>Yeni Müsait Gün Ekle</button>
            </div>
  


      {/* Düzenleme formu */}

      {editAvailableDate && (

        
        
        <div id="available-date-update" style={{ marginLeft: '50px', paddingBottom: '20px' }}>
          <input
            type="date"
            value={editAvailableDate.availableDate}
            onChange={(e) => setEditAvailableDate({ ...editAvailableDate, availableDate: e.target.value })}
          />
          <input
            type="number"
            value={editAvailableDate.doctorId}
            onChange={(e) => setEditAvailableDate({ ...editAvailableDate, doctorId: e.target.value })}
            placeholder="Doctor ID"
            readOnly
          />
   
          <button onClick={handleUpdate} style={{ backgroundColor: '#8294C4' }}>Müsait Gün Güncelle</button>
        </div>
      )}

    {/*  <ul>
        {availableDates.map((date) => (
          <li key={date.id}>
          ID: {date.id} - Date: {date.availableDate} - Doctor ID: {date.doctorId}
            <button onClick={() => handleEditClick(date)}>Düzenle</button>
            <button onClick={() => handleDelete(date.id)}>Sil</button>
          </li>
        ))}
      </ul> */}

      <h3 style={{ marginLeft: '50px', paddingBottom: '20px' }}>Müsait Günler Listesi</h3>
      <div className="available-date-list" style={{ marginLeft: '50px', paddingBottom: '20px' }} >
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Tarih</th>
            <th>Doktor ID</th>
            <th>Düzenle</th>
            <th>Sil</th>
          </tr>
        </thead>
        <tbody>
          {availableDates.map((date) => (
            <tr key={date.id}>
              <td>{date.id}</td>
              <td>{date.availableDate}</td>
              <td>{date.doctorId}</td>
              <td><button className="btn btn-secondary" onClick={() => handleEditClick(date)}>Düzenle</button></td>
              <td><button className="btn btn-danger" onClick={() => handleDelete(date.id)}>Sil</button></td>
            </tr>
          ))}
        </tbody>
    </table>
    </div>
    </div>
  );
}

export default AvailableDateList;
