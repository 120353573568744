import React from "react";
import welcomeImage from "../../cute_pet.jpeg";

function Home() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2 py-5 text-center">
        <img src={welcomeImage} alt="Hoş Geldiniz" style={{ maxWidth: '80%', height: 'auto', marginBottom: '20px' }} />

          <h1 className="mt-3 text-center" style={{ color: "#51829B" }}>Veteriner Yönetim Sistemimize Hoş Geldiniz. </h1>
          <p className="lead text-center" style={{ color: "#4F6F52" }}>Burada, can dostlarımızın sağlık ihtiyaçlarını önemsiyor ve onlara en iyi bakımı sağlamak için çalışıyoruz. </p>
           <p className="lead text-center" style={{ color: "#D37676" }}> Sistemi kullanarak, kliniğimizdeki muayene randevularını kolayca planlayabilirsiniz.</p>
        </div>
      </div>
    </div>
  );
}

export default Home;
